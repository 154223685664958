import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';
import { produce } from 'immer';

import { UpdateUserImportExportPageSize, UpdateUserImportExportSearchQuery, UpdateUserImportExportActivePage, UpdateUserImportExportSort, ResetUserImportExportState } from '@state-management/actions';

import { SortEntries } from '@data';

import { TableState } from '@models/shared';

export interface UsersImportExportStateModel extends TableState { }

const USERS_IMPORT_EXPORT_STATE_DEFAULTÏ: UsersImportExportStateModel = {
    pageSize: SortEntries()[0],
    activePage: 0,
    sort: {
        field: null,
        order: null
    },
    searchQuery: '',
    filtered: false
};

@State<UsersImportExportStateModel>({
    name: 'usersImportExport',
    defaults: USERS_IMPORT_EXPORT_STATE_DEFAULTÏ
})

@Injectable()
export class UserImportExportState {

    @Selector()
    static getImportExportState(state: UsersImportExportStateModel) {
        return state;
    }

    @Action(UpdateUserImportExportPageSize)
    public updateImportExportPageSize(ctx: StateContext<UsersImportExportStateModel>, action: UpdateUserImportExportPageSize) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.pageSize = action.pageSize;
                draft.filtered = true;
            })
        );
    }

    @Action(UpdateUserImportExportSearchQuery)
    public updateImportExportSearchQuery(ctx: StateContext<UsersImportExportStateModel>, action: UpdateUserImportExportSearchQuery) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.searchQuery = action.searchQuery;
                draft.filtered = true;
            })
        );
    }

    @Action(UpdateUserImportExportActivePage)
    public updateImportExportActivePage(ctx: StateContext<UsersImportExportStateModel>, action: UpdateUserImportExportActivePage) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.activePage = action.activePage;
                draft.filtered = true;
            })
        );
    }

    @Action(UpdateUserImportExportSort)
    public updateImportExportSort(ctx: StateContext<UsersImportExportStateModel>, action: UpdateUserImportExportSort) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.activePage = 0;
                draft.sort = action.sort;
                draft.filtered = true;
            })
        );
    }

    @Action(ResetUserImportExportState)
    public resetImportExportState(ctx: StateContext<UsersImportExportStateModel>) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.pageSize = SortEntries()[0];
                draft.activePage = 0;
                draft.sort = USERS_IMPORT_EXPORT_STATE_DEFAULTÏ.sort;
                draft.searchQuery = '';
                draft.filtered = false;
            })
        );
    }
}