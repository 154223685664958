import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { lastValueFrom, map } from 'rxjs';

import { ConfigService, UserService } from '@services';

import { Session } from '@models/shared';

@Injectable({
    providedIn: 'root'
})

export class AuthenticationService {

    constructor(
        private configService: ConfigService,
        private httpClient: HttpClient,
        private userService: UserService,
    ) { }

    public checkSession() {
        const url = this.configService.authApiUrl + '/check_session';
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.userService.getCurrentSession().session_id
            })
        };
        return lastValueFrom(this.httpClient.get<Session>(url, httpOptions).pipe(map(response => response)));
    }

    public login(email: string, password: string) {
        const url = this.configService.authApiUrl;
        const body = {
            email,
            password
        };
        return lastValueFrom(this.httpClient.post<{ user_id: string; client_id: string; session: Session }>(url, body).pipe(map(response => response)));
    }

    public logout() {
        const url = this.configService.authApiUrl + '/logout';
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.userService.getCurrentSession().session_id
            })
        };
        return lastValueFrom(this.httpClient.post<string>(url, null, httpOptions).pipe(map(response => response)));
    }

    public ping() {
        const url = this.configService.authApiUrl + '/ping';
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return lastValueFrom(this.httpClient.get<{ data: string }>(url, httpOptions).pipe(map(response => response)));
    }
}
