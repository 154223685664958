export class UpdateUserImportExportPageSize {
    static readonly type = '[User Import Export] Update User Import Export Page Size';
    constructor(public pageSize: number) { }
}

export class UpdateUserImportExportSearchQuery {
    static readonly type = '[User Import Export] Update User Import Export Search Query';
    constructor(public searchQuery: string) { }
}

export class UpdateUserImportExportActivePage {
    static readonly type = '[User Import Export] Update User Import Export Active Page';
    constructor(public activePage: number) { }
}

export class UpdateUserImportExportSort {
    static readonly type = '[User Import Export] Update User Import Export Sort';
    constructor(public sort: { field: string, order: 1 | -1 }) { }
}

export class ResetUserImportExportState {
    static readonly type = '[User Import Export] Reset User Import Export State';
    constructor() { }
}
